<template>
  <div>
    <viewer ref="viewer" :options="options" class="viewer" @inited="inited">
      <b-media>
        <b-media-aside>
          <div v-for="attachment in attachments" :key="attachment">
            <!-- {{ `${baseURL}${attachment.url}` }} -->
            <b-link
              v-if="attachment.ext == '.pdf'"
              target="_blank"
              :href="`${baseURL}${attachment.url}`"
            >
              <b-img
                src="@/assets/images/icons/file-icons/pdf.png"
                rounded
                center
                fluid
                height="80"
                alt="Responsive image"
              />
              <p>{{ attachment.name }}</p>
            </b-link>

            <b-link
              v-else-if="attachment.ext == '.doc'"
              target="_blank"
              :href="`${baseURL}${attachment.url}`"
            >
              <b-img
                src="@/assets/images/icons/file-icons/doc.png"
                rounded
                center
                fluid
                height="80"
                alt="Responsive image"
              />
              <p>{{ attachment.name }}</p>
            </b-link>

            <b-link
              v-else-if="attachment.ext == '.txt'"
              target="_blank"
              :href="`${baseURL}${attachment.url}`"
            >
              <b-img
                src="@/assets/images/icons/file-icons/file.png"
                rounded
                center
                fluid
                height="80"
                alt="Responsive image"
              />
              <p>{{ attachment.name }}</p>
            </b-link>
            <b-img
              v-if="
                ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(
                  attachment.mime
                ) && attachment.formats
              "
              :src="getAttachmentURL(attachment)"
              class="image"
              :data-src="`${baseURL}${attachment.url}`"
            />

            <b-img
              v-else-if="
                ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(
                  attachment.mime
                )
              "
              src="@/assets/images/icons/file-icons/generic.png"
              class="image"
              :data-src="`${baseURL}${attachment.url}`"
            />

            <b-link
              v-else
              target="_blank"
              :href="`${baseURL}${attachment.url}`"
            >
              <b-img
                src="@/assets/images/icons/file-icons/generic.png"
                rounded
                center
                fluid
                height="80"
                alt="Responsive image"
              />
              <p>{{ attachment.name }}</p>
            </b-link>
            <b-button
              variant="danger"
              class="btn-icon"
              style="float: right"
              @click="deleteAttachmentWarning(attachment)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </b-media-aside>
      </b-media>
    </viewer>
  </div>
</template>

<script>
import { BMedia, BMediaAside, BImg, BLink, BButton } from 'bootstrap-vue'
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from 'v-viewer'
import API from '@/service/api'

export default {
  components: {
    BMedia,
    BMediaAside,
    BImg,
    BLink,
    Viewer,
    BButton,
    API,
  },
  props: ['attachments'],

  data() {
    return {
      options: {
        url: 'data-src',
      },
      baseURL: API().defaults.baseURL,
    }
  },

  updated() {
    this.$viewer.update()
  },

  methods: {
    inited(viewer) {
      this.$viewer = viewer
    },

    deleteAttachmentWarning(attachment) {
      console.log(attachment)

      this.$swal({
        title: 'Bist du sicher?',
        // text: 'Das Lager wird unwiederruflich gelöscht.',
        text: `Anlage ${attachment.name} wirklich löschen?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, löschen!',
        cancelButtonText: 'Abbrechen',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch('deleteFile', attachment.id)
            .then(() => {
              this.$store.dispatch('getFeedbackEntries')
              this.$emit('showSuccessToast')
            })
            .catch(() => {})

          // reload parent component

          // this.$router.go()
        }
      })
    },

    getAttachmentURL(attachment) {
      if (attachment.formats && attachment.formats.medium) {
        return `${this.baseURL}${attachment.formats.medium.url}`
      }
      if (attachment.formats && attachment.formats.thumbnail) {
        return `${this.baseURL}${attachment.formats.thumbnail.url}`
      }
      return `${this.baseURL}${attachment.url}`
    },
    // show() {
    //   this.$viewer.show()
    // },
  },
}
</script>

<style lang="scss">
.image {
  height: 120px;
  cursor: pointer;
  margin-right: 5px;
}
</style>
