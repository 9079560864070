<template>
  <div>
    <!-- {{ getFeedbackbyId }} -->
    <!-- <b-button @click="showSuccessToast"> Show Success Toast </b-button> -->
    <b-row class="match-height">
      <b-col xl="4" md="4" sm="4">
        <b-card :title="getFeedbackbyId.title">
          <b-card-text>
            <!-- Art:
            <b>{{
              getFeedbackTypeTranslation(getFeedbackbyId.feedbackType)
            }}</b> -->
            <v-select
              :value="getFeedbackTypeTranslation(getFeedbackbyId.feedbackType)"
              label="label"
              :options="feedbackTypeOptions"
              required
              :clearable="false"
              @input="changedType"
            />
            <br />
            Erstellt am:
            <b>{{ getFormattedDate(getFeedbackbyId.created_at) }}</b> <br />
            Erstellt von:
            <b>{{ getFeedbackbyId.user }}</b> <br />
            Aktualisiert am:
            <b>{{ getFormattedDate(getFeedbackbyId.updated_at) }}</b>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col xl="4" md="4" sm="4">
        <b-card
          :bg-variant="getPriorityVariant(getFeedbackbyId.priority)"
          text-variant="white"
        >
          <b-card-title class="text-white"> Priorität </b-card-title>
          <b-card-text>
            <v-select
              class="white-select"
              :value="getPriorityTranslation(getFeedbackbyId.priority)"
              label="label"
              :options="priorityOptions"
              required
              :clearable="false"
              @input="changedPriority"
            /> </b-card-text
        ></b-card>
      </b-col>
      <b-col xl="4" md="4" sm="4">
        <b-card
          :bg-variant="getStatusVariant(getFeedbackbyId.status)"
          text-variant="white"
        >
          <b-card-title class="text-white"> Status </b-card-title>
          <b-card-text>
            <v-select
              class="white-select"
              color="white"
              label="label"
              :value="getStatusTranslation(getFeedbackbyId.status)"
              :options="feedbackStatus"
              :clearable="false"
              @input="changedStatus"
            /> </b-card-text
        ></b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col xl="6" md="6" sm="6">
        <b-card title="Beschreibung">
          <b-form-textarea
            id="textarea-description"
            v-model="description"
            placeholder="Beschreibung hinzufügen..."
            rows="5"
            @input="onDescriptionChange"
          />
          <b-card-text />
          <b-button
            :disabled="!showSaveButton"
            variant="primary"
            @click="saveDescription"
          >
            Speichern
          </b-button>
        </b-card>
      </b-col>
      <b-col xl="6" md="6" sm="6">
        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-1 mr-5">Dateien</h4>
          </div>
          <div class="d-flex mb-2">
            <b-form-file
              ref="fileInput"
              v-model="selectedFiles"
              placeholder="Dateien auswählen..."
              multiple
              size="md"
              @input="addFiles"
            />
            <b-button
              :disabled="selectedFiles.length === 0"
              class="ml-5"
              variant="primary"
              @click="uploadFiles"
            >
              Hinzufügen
            </b-button>
          </div>
          <b-card-text>
            <FileViewer
              :attachments="getFeedbackbyId.files"
              @showSuccessuploadFile="showSuccessToast"
            />
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <!-- Add this new comment section in the template within the <div> tag -->
    <b-row class="match-height">
      <b-col xl="12" md="12" sm="12">
        <b-card title="Kommentare">
          <div v-for="(comment, index) in sortedComments" :key="index">
            <div class="title-user-wrapper">
              <div class="ml-1">
                {{ getFormattedDate(comment.created_at) }}
              </div>
              <div class="mr-1">
                {{ comment.user }}
              </div>
            </div>
            <h5 class="mb-1 ml-1 mt-1">
              {{ comment.title }}
            </h5>
            <b-form-textarea
              id="textarea-description"
              v-model="comment.description"
              placeholder="Beschreibung hinzufügen..."
              class="mb-1 gray-background"
              @input="onCommentDescriptionChange(index)"
            />
            <b-card-text />

            <b-button
              v-if="changedCommentIndex === index"
              variant="primary"
              @click="saveCommentDescription"
            >
              Speichern
            </b-button>
            <hr />
          </div>
          <p class="mt-3">Kommentar hinzufügen</p>
          <b-form @submit.prevent="addComment">
            <b-form-group label="Titel">
              <b-form-input
                v-model="commentTitle"
                placeholder="(Optional) Titel hinzufügen..."
                class="mb-1"
              />
            </b-form-group>
            <b-form-group label="Beschreibung">
              <b-form-textarea
                v-model="commentDescription"
                placeholder="Beschreibung hinzufügen..."
                rows="2"
                required
                class="mb-1"
              />
            </b-form-group>

            <b-button type="submit" variant="primary">
              Kommentar hinzufügen
            </b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BCardSubTitle,
  BListGroup,
  BListGroupItem,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BFormTextarea,
  BButton,
  BFormFile,
  BFormInput,
  BForm,
} from 'bootstrap-vue'
import helper from '@/helper/helper'
import vSelect from 'vue-select'
import FileViewer from '@views/pages/FileViewer.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import feedbackHelper from '../FeedbackHelper'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardSubTitle,
    BListGroup,
    BListGroupItem,
    FileViewer,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BLink,
    BFormTextarea,
    BButton,
    ToastificationContent,
    vSelect,
    BFormFile,
    BFormInput,
    BForm,
  },
  props: ['id'],
  data() {
    return {
      feedbackStatus: [
        { status: 'open', label: 'Offen' },
        { status: 'progress', label: 'In Bearbeitung' },
        { status: 'done', label: 'Erledigt' },
        { status: 'closed', label: 'Geschlossen' },
      ],
      feedbackTypeOptions: [
        { label: 'Allgemein', value: 'general' },
        { label: 'Neue Funktion', value: 'feature' },
        { label: 'Fehler', value: 'bug' },
        { label: 'Sonstiges', value: 'other' },
      ],
      priorityOptions: [
        { label: 'Niedrig', value: 'low' },
        { label: 'Mittel', value: 'medium' },
        { label: 'Hoch', value: 'high' },
      ],
      showSaveButton: false,
      description: '',
      selectedFiles: [],
      commentTitle: '',
      commentDescription: '',
      commentUser: '',
      changedCommentIndex: -1,
    }
  },
  computed: {
    // getFeedbackbyId from axios
    getFeedbackbyId() {
      console.log('🚀 ~ file: FeedbackView.vue:173 ~ getFeedbackbyId')

      return this.$store.getters.feedbackById(Number(this.id))
    },
    sortedComments() {
      return this.getFeedbackbyId.comments
        .slice()
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
    },
    currentUser() {
      return this.$store.getters.user
    },
  },
  mounted() {
    this.description = this.getFeedbackbyId.description
  },
  setup() {
    const { getFormattedDate } = helper()
    const {
      getPriorityTranslation,
      getFeedbackTypeTranslation,
      getStatusTranslation,
      getStatusVariant,
      getPriorityVariant,
    } = feedbackHelper()

    return {
      getPriorityTranslation,
      getFeedbackTypeTranslation,
      getStatusTranslation,
      getStatusVariant,
      getPriorityVariant,
      getFormattedDate,
    }
  },
  methods: {
    changedPriority(item) {
      this.getFeedbackbyId.priority = item.value
      this.saveFeedback()
    },
    changedType(item) {
      this.getFeedbackbyId.feedbackType = item.value
      this.saveFeedback()
    },
    changedStatus(item) {
      this.getFeedbackbyId.status = item.status
      this.saveFeedback()
    },
    onDescriptionChange(value) {
      this.description = value
      this.showSaveButton = true
    },

    onCommentDescriptionChange(index) {
      this.changedCommentIndex = index
    },

    saveCommentDescription() {
      const comment = this.sortedComments[this.changedCommentIndex]
      console.log(
        '🚀 ~ file: FeedbackView.vue:320 ~ saveCommentDescription ~ comment:',
        comment
      )

      this.$store
        .dispatch('updateComment', {
          id: comment.id,
          description: comment.description,
        })
        .then(() => {
          this.changedCommentIndex = -1
          console.log('OK: updateComment')
        })
        .catch(() => {
          this.changedCommentIndex = -1
          console.log('Error: updateComment')
        })

      // this.saveFeedback()
    },

    saveDescription() {
      // Your logic to save the description
      this.getFeedbackbyId.description = this.description
      this.saveFeedback()

      this.showSaveButton = false
    },

    uploadFiles() {
      const formData = new FormData()

      for (let i = 0; i < this.selectedFiles.length; i += 1) {
        const file = this.selectedFiles[i]

        formData.append('files', file)
      }

      const config = {
        onUploadProgress: uploadEvent => {
          console.log(
            `Upload Progress: ${Math.round(
              (uploadEvent.loaded / uploadEvent.total) * 100
            )} %`
          )
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      this.$store
        .dispatch('addFile', formData, config)
        .then(result => {
          // Push the file id to the feedback.files array
          result.forEach(a => this.getFeedbackbyId.files.push(a.id))

          //   this.feedback.files.push result.map(a => a.id)
          this.saveFeedback()
        })
        .catch(() => {})
    },

    addFiles() {
      console.log('addFile')
    },

    saveFeedback() {
      this.$store
        .dispatch('updateFeedback', this.getFeedbackbyId)
        .then(() => {
          this.showSuccessToast()
          this.$store.dispatch('getFeedbackEntries')
          this.getFeedbackbyId(Number(this.id))
        })
        .catch(error => {
          console.log(error)
        })
    },

    showSuccessToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Feedback aktualisiert!',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },

    deleteAttachmentWarning(data) {
      console.log(data)

      this.$swal({
        title: 'Bist du sicher?',
        // text: 'Das Lager wird unwiederruflich gelöscht.',
        text: 'Anlage wirklich löschen?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, löschen!',
        cancelButtonText: 'Abbrechen',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('deleteAttachment', data.id)
          this.$router.go()
        }
      })
    },

    addComment() {
      console.log('🚀 ~ file: FeedbackView.vue:382 ~ addComment')

      const newComment = {
        title: this.commentTitle,
        description: this.commentDescription,
        user: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
      }

      this.$store
        .dispatch('addComment', newComment)
        .then(result => {
          this.getFeedbackbyId.comments.push(result)
          this.saveFeedback()
          this.commentTitle = ''
          this.commentDescription = ''
          // eslint-disable-next-line operator-linebreak
          this.commentUser = ''
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scope lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

// .feedback-type-select .vs__selected {
//   color: #000 !important;
// }

.white-select .vs__selected {
  color: #fff !important;
}

.image {
  height: 120px;
  cursor: pointer;
  margin-right: 5px;
}

.title-user-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  color: #a5a5a5;
}

.gray-background#textarea-description {
  background-color: #fbfbfb;
  border-width: 1px;
  border-color: #f3f3f3; /* Adjust this value to your desired shade of gray */
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
