var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('viewer',{ref:"viewer",staticClass:"viewer",attrs:{"options":_vm.options},on:{"inited":_vm.inited}},[_c('b-media',[_c('b-media-aside',_vm._l((_vm.attachments),function(attachment){return _c('div',{key:attachment},[(attachment.ext == '.pdf')?_c('b-link',{attrs:{"target":"_blank","href":("" + _vm.baseURL + (attachment.url))}},[_c('b-img',{attrs:{"src":require("@/assets/images/icons/file-icons/pdf.png"),"rounded":"","center":"","fluid":"","height":"80","alt":"Responsive image"}}),_c('p',[_vm._v(_vm._s(attachment.name))])],1):(attachment.ext == '.doc')?_c('b-link',{attrs:{"target":"_blank","href":("" + _vm.baseURL + (attachment.url))}},[_c('b-img',{attrs:{"src":require("@/assets/images/icons/file-icons/doc.png"),"rounded":"","center":"","fluid":"","height":"80","alt":"Responsive image"}}),_c('p',[_vm._v(_vm._s(attachment.name))])],1):(attachment.ext == '.txt')?_c('b-link',{attrs:{"target":"_blank","href":("" + _vm.baseURL + (attachment.url))}},[_c('b-img',{attrs:{"src":require("@/assets/images/icons/file-icons/file.png"),"rounded":"","center":"","fluid":"","height":"80","alt":"Responsive image"}}),_c('p',[_vm._v(_vm._s(attachment.name))])],1):_vm._e(),(
              ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(
                attachment.mime
              ) && attachment.formats
            )?_c('b-img',{staticClass:"image",attrs:{"src":_vm.getAttachmentURL(attachment),"data-src":("" + _vm.baseURL + (attachment.url))}}):(
              ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(
                attachment.mime
              )
            )?_c('b-img',{staticClass:"image",attrs:{"src":require("@/assets/images/icons/file-icons/generic.png"),"data-src":("" + _vm.baseURL + (attachment.url))}}):_c('b-link',{attrs:{"target":"_blank","href":("" + _vm.baseURL + (attachment.url))}},[_c('b-img',{attrs:{"src":require("@/assets/images/icons/file-icons/generic.png"),"rounded":"","center":"","fluid":"","height":"80","alt":"Responsive image"}}),_c('p',[_vm._v(_vm._s(attachment.name))])],1),_c('b-button',{staticClass:"btn-icon",staticStyle:{"float":"right"},attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteAttachmentWarning(attachment)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1)],1)}),0)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }